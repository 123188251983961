import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Cover, Image, Input, Layout, Link, SEO } from 'components';
import { routes } from 'utils/routes';

import content from './contact.yml';

const formFeedback = [
  'Sending your message...',
  'Your message was successfully sent. We will get back to you as soon as possible.',
  'Ups, something just happened:',
];

const ContactUsPage = ({ pageContext: { locale }, location }) => {
  const [formStatus, handleFormStatus] = useState(-1);
  const [formError, handleFormError] = useState(null);
  const sending = () => handleFormStatus(0);
  const success = () => handleFormStatus(1);
  const failed = () => handleFormStatus(2);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleFormError(null);
    sending();
    // todo: update url
    const url = 'https://www.straightteethdirect.com/wp-json/contact-form-7/v1/contact-forms/17/feedback';
    const data = new FormData(ev.target);

    fetch(url, {
      method: 'POST',
      body: data,
      credentials: "same-origin",
    }).then(function(response) {
      // response.status     //=> number 100–599
      // response.statusText //=> String
      // response.headers    //=> Headers
      // response.url        //=> String
      success();
      // return response.text();
    }, function(error) {
      failed();
      handleFormError(error.message);
    });
  };

  return (
    <Layout locale={locale} className='contacts-wrapper'>
      <SEO
        title={content.title}
        description={content.description}
        lang={locale}
        keywords={content.keywords}
        location={location}
        image={content.socialImage}
      />

      <Cover
        title='Get in touch'
        image={{ url: 'pages/contactus_cover.jpg', alt: 'Contact Straight Teeth Direct' }}
        button={{ label: 'Get started', ...routes.getStarted }}
        trustpilot
      />

      <p className='t-center'>
        If you are new, make sure to start with the <Link to={routes.getStarted.path} {...routes.getStarted}>free e-consultation</Link> to receive a professional opinion from a dentist first.<br/><br/>If you are already registered make sure to contact us through your account’s Message tab.<br/><br/>For all other questions leave a message below.
      </p>

      <form onSubmit={handleSubmit} className='form-wrapper'>
        <Input id='name' name='Name' placeholder='What’s your name?' required />
        <Input id='email' name='Email' placeholder='What’s your email address?' type='email' required />
        <Input id='message' name='Message' placeholder='Write your message here' type='textarea' required />
        {formStatus > -1 &&
          <p className='ml-20 mr-20'>
            <b>
              {formFeedback[formStatus]}
              {formError && <span className='block red'><br/>{formError}</span>}
            </b>
          </p>
        }
        <Button label='Send' level='outline' />
      </form>

      <h3 className='section-title mt-90 mb-10'>Press and media inquiries:</h3>
      <p className='t-center o-60'>press(@)straightteethdirect.com</p>

      <h3 className='section-title mt-40 mb-10'>Job inquiries:</h3>
      <p className='section-title t-center'>Make sure to check our current <Link to={routes.joinTeam.path} {...routes.joinTeam}>open positions</Link> and apply through the website to be considered or to discuss a different role contact:</p>
      <p className='t-center o-60'>talent(@)straightteethdirect.com</p>

      <h2 className='section-title mt-90 mb-50'>Follow us on social</h2>
      <div className='section-social mb-90'>
        <p className='t-center social-tag'>@straightteethdirect</p>

        <div className='social-links'>
          <Link to={routes.facebook.path} {...routes.facebook} className='ml-20 mr-20'>
            <Image url='icons/icon_facebook.svg' alt='Facebook logo' />
          </Link>
          <Link to={routes.youtube.path} {...routes.youtube}>
            <Image url='icons/icon_youtube.svg' alt='Youtube logo' className='social-youtube' />
          </Link>
          <Link to={routes.instagram.path} {...routes.instagram}>
            <Image url='icons/icon_instagram.svg' alt='Instagram logo' />
          </Link>
        </div>
      </div>
    </Layout>
  );
};

ContactUsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactUsPage;
